import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import dashboardReducer from './reducers/dashboardReducer';
import businessProfileReducer from './reducers/businessProfileReducer';
import colorCombinationReducer from './reducers/colorCombinationReducer';
import aboutReducer from './reducers/aboutReducer';
import categoryReducer from './reducers/categoryReducer';
import subCategoryReducer from './reducers/subCategoryReducer';
import taxesReducer from './reducers/taxesReducer';
import navigationMenuReducer from './reducers/navigationMenuReducer';
import teamReducer from './reducers/teamReducer';
import logoReducer from './reducers/logoReducer';
import galleryReducer from './reducers/galleryReducer';
import sliderReducer from './reducers/sliderReducer';
import testimonialReducer from './reducers/testimonialReducer';
import enquiryReducer from './reducers/enquiryReducer';
import productReviewReducer from './reducers/productReviewReducer';
import subscriberReducer from './reducers/subscriberReducer';
import blogPostReducer from './reducers/blogPostReducer';
import customPagesReducer from './reducers/customPagesReducer';
import videosReducer from './reducers/videoReducer';
import faqsReducer from './reducers/faqReducer';
import productReducer from './reducers/productReducer';
import productVariantReducer from './reducers/variantReducer';
import couponReducer from './reducers/couponReducer';
import dealReducer from './reducers/dealReducer';
import orderReducer from './reducers/orderReducer';
import abandonedCartReducer from './reducers/abandonedCartReducer';
import abandonedWishlistReducer from './reducers/abandonedWishlistReducer';
import seoReducer from './reducers/seoReducer';
import webSettingReducer from './reducers/webSettingReducer';
import userReducer from './reducers/userReducer';
import shapeReducer from './reducers/shapeReducer';


const rootReducer = combineReducers({
  auth: authReducer,
  businessProfile: businessProfileReducer,
  colorCombination: colorCombinationReducer,
  about: aboutReducer,
  category: categoryReducer,
  subCategory: subCategoryReducer,
  taxes: taxesReducer,
  navigationMenu: navigationMenuReducer,
  team: teamReducer,
  logo: logoReducer,
  gallery: galleryReducer,
  slider: sliderReducer,
  testimonial: testimonialReducer,
  enquiry: enquiryReducer,
  productReview: productReviewReducer,
  subscriber: subscriberReducer,
  blogPost: blogPostReducer,
  customPages: customPagesReducer,
  videos: videosReducer,
  faqs: faqsReducer,
  product: productReducer,
  shape: shapeReducer,
  productVariant: productVariantReducer,
  coupon: couponReducer,
  deal: dealReducer,
  order: orderReducer,
  abandonedCart: abandonedCartReducer,
  abandonedWishlist: abandonedWishlistReducer,
  seo: seoReducer,
  webSetting: webSettingReducer,
  user: userReducer,
  dashboard: dashboardReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;
