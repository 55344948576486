import { act } from "react-dom/test-utils";
import {
 ADD_SHAPE_FAILURE,
 ADD_SHAPE_REQUEST,
 ADD_SHAPE_SUCCESS,
 DELETE_SHAPE_BANNER_IMAGE_SUCCESS,
 DELETE_SHAPE_FAILURE,
 DELETE_SHAPE_IMAGE_SUCCESS,
 DELETE_SHAPE_REQUEST,DELETE_SHAPE_SUCCESS,
 FETCH_SHAPE_FAILURE,
 FETCH_SHAPE_REQUEST,FETCH_SHAPE_SUCCESS,
 MULTIPLE_DELETE_SHAPE_SUCCESS,
 UPDATE_SHAPE_FAILURE,
 UPDATE_SHAPE_REQUEST,
 UPDATE_SHAPE_SUCCESS
} from "../actions/shapeActions";

// Initial state
const initialState = {
  shape: [],
  loading: false,
  error: null,
};

// Reducer
const shapeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHAPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_SHAPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_SHAPE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_SHAPE_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_SHAPE_SUCCESS:

      return {
        ...state,
        loading: false,
        shape: action.payload,
      };
    case ADD_SHAPE_SUCCESS:
      return {
        ...state,
        loading: false,
        shape: action.payload,
      };
      
      case DELETE_SHAPE_SUCCESS:
      return {
        ...state,
        loading: false,
        shape: state.shape.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_SHAPE_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedTaxes= state.shape.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          shape: updatedTaxes,
        };

        case UPDATE_SHAPE_SUCCESS:
          return {
            ...state,
            loading: false,
            shape: action.payload,
          };

      
    case FETCH_SHAPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_SHAPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_SHAPE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_SHAPE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default shapeReducer;
