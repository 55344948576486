import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-form-wizard-component/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import * as Yup from "yup";
import { BASE_URL, GET_SHAPE_URL } from "../../apis/url_constants";
import {
  addShapeRequest,
  fetchShapeRequest,
  updateShapeRequest,
} from "../../redux_store/actions/shapeActions";



const AddShape = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { shape, loading, error } = useSelector((state) => state.shape);

  const [shapeList , setShapeList] = useState([]);

  const [initialValues, setInitialValues] = useState({
    Name: "",
    Image: null,
    Description: "",
    SortOrder: 0,
  });

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Shape name is required"),
    Image: Yup.mixed().notRequired(),
    SortOrder: Yup.number(),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateShapeRequest(values, id, navigate))
      : dispatch(addShapeRequest(values, navigate));
  };

  useEffect(() => {
    if (editMode) {
      dispatch(fetchShapeRequest(id));
    }
  }, [editMode, dispatch, id]);



  useEffect(() => {

    const fetchData = async () => {
      try {
        const shapeEndpoint = GET_SHAPE_URL;
        const token = localStorage.getItem('token');
        
        const shapeResponse = await axios.get(shapeEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShapeList(shapeResponse.data);

      } catch (error) {
        // Handle error if needed
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    if (editMode && shape && shape.length > 0) {
      const filteredShape = shape[0];
console.log(filteredShape)
      setInitialValues({
        Name: filteredShape.name !== null ? filteredShape.name : "",
        Description: filteredShape.description !== null ? filteredShape.description : "",
        SortOrder: filteredShape.sortOrder !== null ? filteredShape.sortOrder : 0,
      });
    }
  }, [editMode, shape]);


  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>
              {viewMode ? (
                <span>View Shape</span>
              ) : !viewMode && editMode ? (
                <span>Edit Shape</span>
              ) : (
                <span>Add Shape</span>
              )}
            </h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, initialValues, values }) => (
              
                <Form>
                  <div className="row">
                    <h5 className="mb-4 bg-dark py-2 text-white rounded-1">
                      Shape Description
                    </h5>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Name"
                          className="form-label fw-semibold"
                        >
                          Name
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Name"
                            name="Name"
                            disabled={viewMode}
                            onChange={(event) => {
                              const name = event.target.value;
                              setFieldValue("Name", name);
                              setFieldValue(
                                "Slug",
                                name
                                  .toLowerCase()
                                  .replace(/[^a-zA-Z0-9 ]/g, "")
                                  .replace(/ /g, "-")
                              );
                            }}
                            className="form-control border-0 ps-2"
                            placeholder="Enter Name here"
                          />
                        </div>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                 
                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Image"
                          className="form-label fw-semibold"
                        >
                          Image
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="Image"
                            name="Image"
                            disabled={viewMode}
                            onChange={(event) => {
                              const file = event.target.files[0];
                              setFieldValue("Image", file || null);
                            }}
                            className="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="Image"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode && (
                        <>
                          {shape &&
                          shape.length > 0 &&
                          !loading &&
                          shape[0].image ? (
                            <div className="py-3">
                              <img
                                src={`${BASE_URL}${shape[0].image}`}
                                alt="Image"
                                style={{ height: "60px", width: "auto" }}
                              />
                            </div>
                          ) : loading || shape === undefined ? (
                            <div>Loading image...</div>
                          ) : (
                            <div>No image available</div>
                          )}
                        </>
                      )}
                    </div>

                   
                  
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label
                          htmlFor="Description"
                          className="form-label fw-semibold"
                        >
                          Description
                        </label>
                        <div className=" border rounded-1">
                          <CKEditor
                            name="Description"
                            id="Description"
                            disabled={viewMode}
                            editor={ClassicEditor}
                            data={initialValues.Description || ""}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("Description", data);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="Description"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="SortOrder"
                          className="form-label fw-semibold"
                        >
                          Sort Order
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="SortOrder"
                            name="SortOrder"
                            className="form-control"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="SortOrder"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                  </div>
                  {viewMode ? (
                    <div className="py-3"></div>
                  ) : (
                    <div className="action-form">
                      <div className="mb-3 mb-0 text-end">
                        <button
                          type="submit"
                          className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                        >
                          Save
                        </button>
                        <Link to="/shape" className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2">Cancel</Link>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddShape;
