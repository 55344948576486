import React from "react";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchCategoryRequest,
  updateCategoryRequest,
  addCategoryRequest,
} from "../../redux_store/actions/categoryActions";
import {
  fetchSubCategoryRequest,
  updateSubCategoryRequest,
  addSubCategoryRequest,
} from "../../redux_store/actions/subCategoryActions";
import { ColorRing } from "react-loader-spinner";
import { BASE_URL } from "../../apis/url_constants";

const AddSubCategory = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { subCategory, loading, error } = useSelector(
    (state) => state.subCategory
  );

  const { category, cat_loading, cat_error } = useSelector(
    (state) => state.category
  );

  const [initialValues, setInitialValues] = useState({
    Name: "",
    Slug: "",
    Status: false,
    SortOrder: "",
    CategoryId: null,
  });

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is Required"),
    Slug: Yup.string().required("Slug is Required"),
    Status: Yup.boolean(),
    SortOrder: Yup.number()
      .integer()
      .nullable()
      .min(1, "Sort Order must be greater than or equal to 1").required("SortOrder is Required"),
    CategoryId: Yup.number().nullable().required("Please Select a Category"),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateSubCategoryRequest(values, id, navigate))
      : 
      // console.log(values)
      dispatch(addSubCategoryRequest(values, navigate));
  };

  useEffect(() => {

    // fetching categories for dropdown
    dispatch(fetchCategoryRequest());


    if (editMode) {
      dispatch(fetchSubCategoryRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && subCategory && subCategory.length > 0) {
      const filteredCategory = subCategory[0];

      setInitialValues({
        Name: filteredCategory.name !== null ? filteredCategory.name : "",
        Slug: filteredCategory.slug !== null ? filteredCategory.slug : "",
        Status: filteredCategory.status || false,
        SortOrder: filteredCategory.sortOrder || "",
        CategoryId: filteredCategory.categoryId || null,
      });
    }
  }, [editMode, subCategory]);


  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>{editMode && viewMode ? <text>View SubCategory</text> : !viewMode && editMode ? <text>Edit SubCategory</text> : <text>Add SubCategory</text>}</h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Name"
                          className="form-label fw-semibold"
                        >
                          Name
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Name"
                            name="Name"
                            onChange={(event) => {
                              const title = event.target.value;
                              setFieldValue("Name", title);
                              setFieldValue(
                                "Slug",
                                title
                                  .toLowerCase()
                                  .replace(/[^a-zA-Z0-9 ]/g, "")
                                  .replace(/ /g, "-")
                              );
                            }}
                            className="form-control border-0 ps-2"
                            placeholder="Category Name"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Slug"
                          className="form-label fw-semibold"
                        >
                          Slug
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-link fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Slug"
                            name="Slug"
                            className="form-control border-0 ps-2"
                            placeholder="Slug"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Slug"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="mb-4">
                                              <label
                                                  htmlFor="Image"
                                                  className="form-label fw-semibold"
                                              >
                                                  Image
                                              </label>
                                              <div className="input-group border rounded-1">
                                                  <span
                                                      className="input-group-text bg-transparent px-6 border-0"
                                                      id="basic-addon1"
                                                  >
                                                      <i className="ti ti-photo fs-6"></i>
                                                  </span>
                                                  <input
                                                      type="file"
                                                      id="Image"
                                                      name="Image"
                                                      disabled={viewMode}
                                                      onChange={(event) => {
                                                          const file = event.target.files[0];
                                                          setFieldValue("Image", file || null);
                                                      }}
                                                      className="form-control"
                                                  />
                                              </div>
                                              <ErrorMessage
                                                  name="Image"
                                                  component="div"
                                                  className="mt-1 error"
                                              />
                                          </div>

                                          {editMode && (
                                              <>
                                                  {subCategory &&
                                                      subCategory.length > 0 &&
                                                      !loading &&
                                                      subCategory[0].image ? (
                                                      <div className="py-3">
                                                              <img
                                                                  src={`${BASE_URL}${subCategory[0].image}`}
                                                              alt="Image"
                                                              style={{ height: "60px", width: "auto" }}
                                                          />
                                                      </div>
                                                  ) : loading || subCategory === undefined ? (
                                                      <div>Loading image...</div>
                                                  ) : (
                                                      <div>No image available</div>
                                                  )}
                                              </>
                                          )}
                                      </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="CategoryId"
                          className="form-label fw-semibold"
                        >
                          Category
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-category-2 fs-6"></i>
                          </span>
                          <Field
                            as="select"
                            id="CategoryId"
                            name="CategoryId"
                            className="form-select border-0 ps-2"
                            disabled={viewMode}
                          >
                            <option value="" selected>
                              Select Category
                            </option>
                            {category.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <ErrorMessage
                          name="CategoryId"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="SortOrder"
                          className="form-label fw-semibold"
                        >
                          Sort Order
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="SortOrder"
                            name="SortOrder"
                            className="form-control"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="SortOrder"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-3"></div>

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="Status"
                          name="Status"
                          className="form-check-input"
                          disabled={viewMode}
                        />
                        <label
                          htmlFor="Status"
                          className="fw-semibold form-check-label"
                        >
                          Status
                        </label>

                        <ErrorMessage
                          name="Status"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                  </div>

                  {viewMode ? <div className="py-3"></div> :
                  <div className="action-form">
                    <div className="mb-3 mb-0 text-end">
                      <button
                        type="submit"
                        className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                      >
                        Save
                      </button>
                      <Link
                        to="/subCategory"
                        className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div> }
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSubCategory;
