import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_SHAPE_URL, ADD_SHAPE_URL, DELETE_SHAPE_URL, MULTIPLE_SHAPE_DELETE_URL, UPDATE_SHAPE_URL, DELETE_SHAPE_IMAGE_URL, DELETE_SHAPE_BANNER_IMAGE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_SHAPE_REQUEST = 'FETCH_SHAPE_REQUEST';
export const FETCH_SHAPE_SUCCESS = 'FETCH_SHAPE_SUCCESS';
export const FETCH_SHAPE_FAILURE = 'FETCH_SHAPE_FAILURE';

export const ADD_SHAPE_REQUEST = 'ADD_SHAPE_REQUEST';
export const ADD_SHAPE_SUCCESS = 'ADD_SHAPE_SUCCESS';
export const ADD_SHAPE_FAILURE = 'ADD_SHAPE_FAILURE';

export const DELETE_SHAPE_REQUEST = 'DELETE_SHAPE_REQUEST';
export const DELETE_SHAPE_SUCCESS = 'DELETE_SHAPE_SUCCESS';
export const DELETE_SHAPE_FAILURE = 'DELETE_SHAPE_FAILURE';


export const UPDATE_SHAPE_REQUEST = 'UPDATE_SHAPE_REQUEST';
export const UPDATE_SHAPE_SUCCESS = 'UPDATE_SHAPE_SUCCESS';
export const UPDATE_SHAPE_FAILURE = 'UPDATE_SHAPE_FAILURE';

export const MULTIPLE_DELETE_SHAPE_SUCCESS = 'MULTIPLE_DELETE_SHAPE_SUCCESS';

export const DELETE_SHAPE_IMAGE_SUCCESS = 'DELETE_SHAPE_IMAGE_SUCCESS';
export const DELETE_SHAPE_BANNER_IMAGE_SUCCESS = 'DELETE_SHAPE_BANNER_IMAGE_SUCCESS';

export const fetchShapeRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_SHAPE_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_SHAPE_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const shape = response.data;
      dispatch({
        type: FETCH_SHAPE_SUCCESS,
        payload: [shape],
      });
    } else {
      const shapeList = response.data;

      dispatch({
        type: FETCH_SHAPE_SUCCESS,
        payload: shapeList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SHAPE_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};


export const fetchShapeSuccess = (product) => ({
  type: FETCH_SHAPE_SUCCESS,
  payload: product,
});

export const fetchShapeFailure = (error) => ({
  type: FETCH_SHAPE_FAILURE,
  payload: error,
});

export const addShapeRequest = (product, navigate) => async (dispatch) => {
  dispatch({ type: ADD_SHAPE_REQUEST });
  const token = localStorage.getItem('token');

  console.log(product)
  try {
    const formData = new FormData();

    formData.append('Name', product.Name);
    formData.append('Image', product.Image);
    formData.append('Description', product.Description);

    formData.append('SortOrder', product.SortOrder);


    const response = await axios.post(ADD_SHAPE_URL, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({ type: ADD_SHAPE_SUCCESS, payload: [response.data] });
    navigate('/shape');

  } catch (error) {
    dispatch({
      type: ADD_SHAPE_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data)
  }
};

export const addShapeSuccess = () => ({
  type: ADD_SHAPE_SUCCESS,
});

export const addShapeFailure = (error) => ({
  type: ADD_SHAPE_FAILURE,
  payload: error,
});


export const deleteShapeRequest = (id) => async (dispatch) => {
  dispatch({ type: DELETE_SHAPE_REQUEST });
  const token = localStorage.getItem('token');

  try {
    await axios.delete(`${DELETE_SHAPE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: DELETE_SHAPE_SUCCESS, payload: { deletedId: id } });
  } catch (error) {
    dispatch({
      type: DELETE_SHAPE_FAILURE,
      payload: error.message,
    });
    toast.error('Failed to delete Shape');
  }
};

export const deleteShapeSuccess = () => ({
  type: DELETE_SHAPE_SUCCESS,
});

export const deleteShapeFailure = (error) => ({
  type: DELETE_SHAPE_FAILURE,
  payload: error,
});


export const multipleDeleteShapeRequest = (ids) => async (dispatch) => {
  dispatch({ type: DELETE_SHAPE_REQUEST });
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(MULTIPLE_SHAPE_DELETE_URL, ids, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: MULTIPLE_DELETE_SHAPE_SUCCESS, payload: ids });

  } catch (error) {
    console.log(error);
    dispatch({
      type: DELETE_SHAPE_FAILURE,
      payload: error.message,
    });
    // Handle error message if needed
  }
};

export const updateShapeRequest = (product, id, navigate) => async (dispatch) => {
  dispatch({ type: UPDATE_SHAPE_REQUEST });
  const token = localStorage.getItem('token');

  console.log(product);
  try {

    const formData = new FormData();
    formData.append('Id', id);
    formData.append('Name', product.Name);
    formData.append('Image', product.Image);
    formData.append('Description', product.Description);
    formData.append('SortOrder', product.SortOrder);

    const response = await axios.put(`${UPDATE_SHAPE_URL}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });



    dispatch({ type: UPDATE_SHAPE_SUCCESS, payload: [response.data] });
    navigate('/shape');

  } catch (error) {
    dispatch({
      type: UPDATE_SHAPE_FAILURE,
      payload: error.message,
    });
    toast.error('Failed to update Shape');
  }
};



