import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_SUBCATEGORY_URL , ADD_SUBCATEGORY_URL, DELETE_SUBCATEGORY__URL, UPDATE_SUBCATEGORY__URL, MULTIPLE_SUBCATEGORY_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_SUBCATEGORY_REQUEST = 'FETCH_SUBCATEGORY_REQUEST';
export const FETCH_SUBCATEGORY_SUCCESS = 'FETCH_SUBCATEGORY_SUCCESS';
export const FETCH_SUBCATEGORY_FAILURE = 'FETCH_SUBCATEGORY_FAILURE';

export const ADD_SUBCATEGORY_REQUEST = 'ADD_SUBCATEGORY_REQUEST';
export const ADD_SUBCATEGORY_SUCCESS = 'ADD_SUBCATEGORY_SUCCESS';
export const ADD_SUBCATEGORY_FAILURE = 'ADD_SUBCATEGORY_FAILURE';

export const DELETE_SUBCATEGORY_REQUEST = 'DELETE_SUBCATEGORY_REQUEST';
export const DELETE_SUBCATEGORY_SUCCESS = 'DELETE_SUBCATEGORY_SUCCESS';
export const DELETE_SUBCATEGORY_FAILURE = 'DELETE_SUBCATEGORY_FAILURE';

export const MULTIPLE_DELETE_SUBCATEGORY_SUCCESS = 'MULTIPLE_DELETE_SUBCATEGORY_SUCCESS';

export const UPDATE_SUBCATEGORY_REQUEST = 'UPDATE_SUBCATEGORY_REQUEST';
export const UPDATE_SUBCATEGORY_SUCCESS = 'UPDATE_SUBCATEGORY_SUCCESS';
export const UPDATE_SUBCATEGORY_FAILURE = 'UPDATE_SUBCATEGORY_FAILURE';



export const fetchSubCategoryRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_SUBCATEGORY_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_SUBCATEGORY_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const subCategory = response.data;
      dispatch({
        type: FETCH_SUBCATEGORY_SUCCESS,
        payload: [subCategory],
      });
    } else {
      const subCategoryList = response.data;
      dispatch({
        type: FETCH_SUBCATEGORY_SUCCESS,
        payload: subCategoryList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SUBCATEGORY_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchSubCategorySuccess = (subCategory) => ({
    type: FETCH_SUBCATEGORY_SUCCESS,
    payload: subCategory,
  });
  
  export const fetchSubCategoryFailure = (error) => ({
    type: FETCH_SUBCATEGORY_FAILURE,
    payload: error,
  });
  
  export const addSubCategoryRequest = (subCategory, navigate) => async (dispatch) => {
    dispatch({ type: ADD_SUBCATEGORY_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Name', subCategory.Name);
        formData.append('Slug', subCategory.Slug);
        formData.append('Image', subCategory.Image);
        formData.append('Status', subCategory.Status);
        formData.append('SortOrder', subCategory.SortOrder);     
        formData.append('CategoryId', subCategory.CategoryId);     


      const response = await axios.post(ADD_SUBCATEGORY_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_SUBCATEGORY_SUCCESS, payload: [response.data] });
      navigate('/subCategory'); 

  } catch (error) {
    dispatch({
      type: ADD_SUBCATEGORY_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding SubCategory.")
  }
  };
  
  export const addSubCategorySuccess = () => ({
    type: ADD_SUBCATEGORY_SUCCESS,
  });
  
  export const addSubCategoryFailure = (error) => ({
    type: ADD_SUBCATEGORY_FAILURE,
    payload: error,
  });


  export const deleteSubCategoryRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_SUBCATEGORY_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_SUBCATEGORY__URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_SUBCATEGORY_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_SUBCATEGORY_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete SubCategory');
    }
  };

  export const deleteSubCategorySuccess = () => ({
    type: DELETE_SUBCATEGORY_SUCCESS,
  });
  
  export const deleteSubCategoryFailure = (error) => ({
    type: DELETE_SUBCATEGORY_FAILURE,
    payload: error,
  });


  export const multipleDeleteSubCategoryRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_SUBCATEGORY_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_SUBCATEGORY_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_SUBCATEGORY_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_SUBCATEGORY_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateSubCategoryRequest = (subCategory, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_SUBCATEGORY_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', subCategory.Name);
        formData.append('Slug', subCategory.Slug);
        formData.append('Image', subCategory.Image);
        formData.append('Status', subCategory.Status);
        formData.append('SortOrder', subCategory.SortOrder);
        formData.append('CategoryId', subCategory.CategoryId);

      const response = await axios.put(`${UPDATE_SUBCATEGORY__URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_SUBCATEGORY_SUCCESS, payload: [response.data] });
      navigate('/subCategory'); 

    } catch (error) {
      dispatch({
        type: UPDATE_SUBCATEGORY_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update SubCategory');
    }
  };
  

  
  
